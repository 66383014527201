.user-avatar {
  position: relative;
  background: #747373;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  .avatar-edit {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    img {
      width: 20px;
      height: 20px;
      border-radius: 0 !important;
    }
    .form-control-file {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.row-block {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .file-inp {
    display: none;
  }

  img {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }

  .area textarea {
    width: 45vw !important;
  }

  .photos {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    position: relative;

    .photo-item {
      margin-right: 20px;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }

}

.control-tbl {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  img {
    width: 20px;
    height: 20px;
  }
}

#yandex-map-react {
  width: 100%;
  height: 450px;
}

.removeCross {
  margin-top: 30px;
  margin-left: 10px;
  color: red;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
}

.stable-width {
  width: 110px;
}

.day-week {
  width: 20px;
}

.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .forming {
    background-color: $blocks-bg;
    padding: 15px;
  }
}
